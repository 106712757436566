.Home {
  width: 100%;
  overflow: hidden;
  position: relative;
}
.top-pic {
  width: 100%;
  height: auto;
  background: #fff;
  object-fit: cover;
  margin: 0 auto;
  overflow: hidden;
}
.icon-pic {
  width: 62px;
  height: 52px;
  margin: 40px 0 0 0;
  float: left;
}

.nav-text {
  font-size: 20px;
  font-weight: 500;
  color: #409cff;
  margin-left: 10px;
}

.Nav-wrapper {
  width: 100%;
  height: 133px;
  background-color: #fafbfd;
  overflow: hidden;
}

.Nav {
  width: 1200px;
  display: flex;
  margin: 0 auto;
  line-height: 133px;
}

.button-group-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 20px;
  right: 20px;
}

.button-group {
  height: 40px;
  color: #fff;
  font-size: 14px;
  margin: 0 23px;
  cursor: pointer;
  display: flex;
  line-height: 22px;
  position: relative;
}

.button-group:hover .button-spector {
  display: none;
}

.button-item {
  background-color: rgba(0, 82, 204, 1);
  border-radius: 8px;
  padding: 10px 20px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.button-item:first-child {
  margin-right: 20px;
}

.button-item.isLogin {
  width: 80px;
}

.button-spector {
  position: absolute;
  left: 96px;
  top: 10px;
}

.button-item:hover {
  background-color: #fff;
  color: #000;
}
.button-item:hover .Popover--wrapper {
  display: block;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.Nav ul {
  flex: 1;
  height: 133px;
  text-align: right;
}

.Nav li {
  display: inline;
  line-height: 60px;
  text-align: center;
}

.Nav-item {
  padding: 0 23px;
  color: #767676;
  display: inline-block;
  font-size: 20px;
  font-weight: normal;
  text-decoration: none;
  text-align: center;
}

.Nav-item.active {
  color: #409cff;
}

.friend {
  text-align: left;
  line-height: 60px;
  margin: 0 60px;
}
.friend a {
  margin: 0 20px;
  color: #0084ff;
}
