.homeVideoList {
  width: 1200px;
  margin: 0 auto 40px;
  overflow: hidden;
}

.video-happy-group {
  width: 1200px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 38px;
}

.HomeVideoCard {
  position: relative;
  width: 344px;
  text-align: left;
  margin: 0;
  overflow: hidden;
}

.happyItem {
  overflow: hidden;
}

.happyItem:nth-child(odd) {
  background-color: #f4f5f7;
}

.happy-title-block {
  width: 1200px;
  /* height: 28px; */
  margin: 44px auto 41px;
  cursor: pointer;
  overflow: hidden;
}
.happy-title {
  float: left;
  font-size: 22px;
  display: block;
  margin: 0;
  color: #514f4f;
  font-weight: 500;
  position: relative;
  border: solid #409cff 2px;
  border-width: 0 0 2px 0;
  margin-bottom: 3px;
  padding-bottom: 3px;
}

.happy-link {
  float: right;
  font-size: 16px;
  line-height: 28px;
  position: relative;
  margin-right: 10px;
  color: #514f4f;
  font-weight: 500;
}

.happy-link:after {
  content: '';
  width: 7px;
  height: 7px;
  display: block;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  border: solid #409cf4 1px;
  border-width: 0px 1px 1px 0;
  float: right;
  margin-top: 9px;
  margin-left: 2px;
}
