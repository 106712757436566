.Pagination {
  font-size: 14px;
  padding: 0;
  text-align: center;
  margin: 0 auto;
  color: #434242;
}

.PaginationButton {
  color: #434242;
  transition: background-color 200ms;
  width: 25px;
  line-height: 25px;
  display: inline-block;
  padding: 0;
  margin-right: 10px;
}

.PaginationButton--current {
  color: #fff;
  background: #409cff;
  border-radius: 50%;
}

.PaginationButton--current:hover {
  color: #fff;
}

.PaginationButton-prev:after {
  content: '';
  width: 7px;
  height: 7px;
  display: block;
  transform: rotate(135deg);
  border: solid #434242 1px;
  border-width: 0px 1px 1px 0;
  float: right;
  margin-top: 9px;
  margin-left: 2px;
}

.PaginationButton-next:after {
  content: '';
  width: 7px;
  height: 7px;
  display: block;
  transform: rotate(-45deg);
  border: solid #434242 1px;
  border-width: 0px 1px 1px 0;
  float: right;
  margin-top: 9px;
  margin-left: 2px;
}

.PaginationButton-next {
  margin-right: 24px;
}
