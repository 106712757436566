.App,
.primary-layout {
  width: 100%;
}

.title-page {
  text-align: center;
  margin-bottom: 70px;
  color: #888;
}

.about-page .title-description,
.about-page .contact {
  font-size: 16px;
  font-weight: normal;
  text-align: left;
  line-height: 1.86;
  margin: 30px 0 0 0;
  display: inline-block;
  color: #514f4f;
}

.about-page .contact {
  margin: 30px 0 71px 0;
}

.subtitle-page {
  font-size: 20px;
  font-weight: normal;
  text-align: left;
  margin: 0;
}

.friend {
  color: #767676;
  font-size: 20px;
  margin-left: 100px;
  margin-top: 10px;
}

.friend a {
  font-size: 16px;
  color: #409cff;
  font-weight: 300;
}

footer {
  width: 100%;
  font-size: 12px;
  font-weight: 300;
  color: #888;
  text-align: center;
  background-color: #f4f5f7;
  padding: 40px 0;
}

footer .credits {
  font-size: 14px;
  margin-bottom: 20px;
  text-transform: uppercase;
}

footer .credits a + a {
  margin-left: 30px;
}

.VideoPage .VideoList-opr {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.qualification,
.certificate {
  text-align: center;
}

.about-link {
  color: #767676;
  margin: 0 0 0 10px;
}

.title-page.VideoPage {
  width: 1200px;
  margin: 36px auto 0;
  text-align: left;
  overflow: hidden;
}

.section-title {
  font-size: 28px;
  font-weight: 500;
  line-height: 40px;
  color: #514f4f;
  display: inline;
  margin: 0;
  border: solid #409cff 2px;
  border-width: 0 0 2px 0;
  padding-bottom: 5px;
}

.our-pic {
  width: 100%;
  height: 236px;
  margin-top: 36px;
  object-fit: cover;
}
