.dashboard {
  display: flex;
  width: 1200px;
  justify-content: space-between;
  align-content: center;
  margin: 70px auto;
  color: #767676;
  font-weight: 300;
  position: relative;
}

.dashboard .title {
  float: left;
  font-size: 20px;
  font-weight: 600;
  line-height: 50px;
  color: #767676;
  display: block;
  padding-right: 35px;
  border: solid #409cff 2px;
  border-width: 0 2px 0 0;
}

.dashboard-list {
  display: flex;
  flex: 1;
  justify-content: center;
}

.dashboard-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dashboard-content {
  width: 120px;
  font-size: 20px;
  line-height: 1.5;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.dashboard-title {
  font-size: 20px;
  font-weight: 300;
  color: #767676;
  margin-bottom: 7px;
}
.dashboard-count {
  font-size: 16px;
  margin: 0;
  color: #343434;
  font-weight: 600;
}
