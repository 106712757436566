.VideoList {
  position: relative;
  margin-top: 36px;
}

.video-group {
  width: 1200px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 38px;
}

.VideoList-prev,
.VideoList-next {
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
  height: 20px;
  display: inline-block;
}

.VideoList-prev {
  right: 25px;
}

.VideoList-prev:after,
.VideoList-next:after {
  content: '';
  width: 16px;
  height: 16px;
  display: block;
  transform: rotate(135deg);
  border: solid #8d8d8d 1px;
  border-width: 0 1px 1px 0;
  margin-top: 2px;
}
.VideoList-arrow.active:after {
  border-color: #409cff;
}

.VideoList-next:after {
  transform: rotate(-45deg);
}

.VideoList-content {
  width: 1200px;
  margin: 20px auto 0;
}

.subtitle-page {
  width: 1200px;
  margin: 0 auto;
  display: block;
}

.video-item {
  position: relative;
  width: 344px;
  text-align: left;
  overflow: hidden;
}

.video-item-mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

iframe {
  width: 344px;
  height: 212px;
  border: none;
}

.video-itemCount {
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #656464;
  margin: 0;
}

.video-itemTitle {
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  color: #514f4f;
  margin: 8px 0 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.VideoList-more {
  position: relative;
  font-size: 14px;
  margin-left: 20px;
}

.VideoList-more a {
  color: #fff;
}

.VideoList-more:after {
  content: '';
  width: 8px;
  height: 8px;
  display: block;
  transform: rotate(-45deg);
  border: solid 1px #ffffff;
  border-width: 0 1px 1px 0;
  position: absolute;
  right: -12px;
  top: 5px;
}

.VideoList-content {
  margin-bottom: 40px;
}
