.card-list {
  margin-bottom: 80px;
  width: 1220px;
  margin: 0 auto 60px;
}

.card {
  display: flex;
  width: 1220px;
  margin: 0 auto;
}

.card-title {
  font-size: 22px;
  font-weight: 600;
  margin: 50px 0 14px 0;
  color: #767676;
}
.card-title small {
  font-size: 20px;
  font-weight: 600;
}

.card-pic {
  width: 480px;
  height: 275px;
}

.card-block {
  position: relative;
  flex: 1;
  margin: 89px 0 0 37px;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-desc {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.8;
  color: #514f4f;
  display: block;
  margin: 0 0 19px 0;
}

.card-desc:after {
  content: '';
  display: block;
  width: 47px;
  height: 2px;
  background-color: #514f4f;
  margin-top: 15px;
}

.card-content {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.8;
  color: #767676;
}

.card-btn {
  width: 190px;
  height: 50px;
  background: #409cff;
  color: #fff;
  line-height: 50px;
  border-radius: 4px;
  display: flex;
  text-align: center;
  position: absolute;
  bottom: 10px;
  overflow: hidden;
}
.card-btn:hover {
  color: #fff;
}
.card-btn-play {
  width: 30px;
  height: 30px;
  margin-top: 10px;
  margin-left: 33px;
  margin-right: 14px;
}
