html,
body {
  height: 100%;
  margin: 0;
}

html,
hmtl a {
  width: 100%;
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  background: #fff;
  color: #888;
  font-weight: 300;
  font-size: 16px;
  font-family: 'Titillium Web', sans-serif;
  line-height: 28px;
}

a {
  color: #de5e60;
  text-decoration: none;
  cursor: pointer;

  -webkit-transition: color 0.1s linear 0s;
  -moz-transition: color 0.1s linear 0s;
  -o-transition: color 0.1s linear 0s;
  transition: color 0.1s linear 0s;
}

a:hover,
a:active,
a:focus {
  outline: 0;
  color: #409cff;
  text-decoration: none;
}

a img {
  border: none;
}

a > img {
  vertical-align: bottom;
}

img {
  max-width: none;
}

strong {
  font-weight: 500;
}

p {
  margin: 0 0 30px;
}

hr {
  border-style: dashed;
  border-color: #3c3f45;
  border-bottom: none;
  border-left: none;
  border-right: none;
  margin: 50px 0;
}

.align_left {
  float: left;
  margin: 0 25px 0 0px !important;
}

.align_right {
  float: right;
  margin: 0 0 0 25px !important;
}

.text_align_left {
  text-align: left;
}

.text_align_center {
  text-align: center;
}

.text_align_right {
  text-align: right;
}

.color-text {
  color: #de5e60;
}

.through {
  text-decoration: line-through;
}

input,
button,
select,
textarea {
  font-weight: 300;
  font-family: 'Titillium Web', sans-serif;
  outline: 0;
}

input:focus,
textarea:focus,
input[type='text']:focus {
  outline: 0;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #7f8289;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #7f8289;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #7f8289;
}
