.Popover--mask {
  padding: 40px;
  position: absolute;
  left: -35px;
  top: 7px;
  z-index: 10;
}
.Popover--wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 10px 13px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  list-style: none;
  display: block;
  min-width: 76px;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 5px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
}

.Popover-unshow {
  display: none;
}

.Popover-list-item {
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s;
  display: block;
  text-align: center;
  line-height: 20px;
  padding: 5px;
}

.Popover-list-item:hover {
  background-color: #ccc;
}
